

































































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { StatisticTheme, Statistic } from './ui-statistic.contract';

@Component({
  name: 'UiStatistic',
})
export default class UiStatistic extends Vue {
  @Prop({
    required: true,
    type: Object as () => Statistic,
  })
  statistic!: Statistic;

  @Prop({
    type: String as () => StatisticTheme,
    default: 'light',
  })
  theme: StatisticTheme;

  @Prop({
    type: Boolean,
    default: true,
  })
  hasBtn: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  needDate: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  isShowDate: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  isShowViews: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  isComments: boolean;

  get commentsLinkTarget() {
    return this.statistic.comments?.target || '_self';
  }

  get isCustomBtnIcon() {
    return guardUnspecified(this.$slots.default);
  }

  commentClicked(event: Event) {
    this.$emit('commentClicked', event);
  }

  btnClicked(event: Event) {
    this.$emit('btnClicked', event);
  }
}









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'UiPageBlockH1',
})
export default class UiPageBlockH1 extends Vue {
  @Prop({
    type: String,
    default: 'Все новости',
  })
  title: string;
}

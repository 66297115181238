


































import { guardEmptyString } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiObserveVisibility } from '../ui-observe-visibility';

@Component({
  name: 'UiBaseBlock',
  components: { UiObserveVisibility },
})
export default class UiBaseBlock extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  isDark!: boolean;
  @Prop({
    type: String,
    default: '',
  })
  title!: string;
  @Prop({
    type: String,
    default: '',
  })
  link!: string;

  @Prop({
    type: String,
    default: '_self',
  })
  linkTarget!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasBorder: boolean;

  get classes() {
    return [
      this.$style.block,
      this.isDark ? this.$style.dark : '',
      this.hasBorder && this.$style.border,
    ];
  }

  get isTitle() {
    return guardEmptyString(this.title);
  }

  get hasLink() {
    return guardEmptyString(this.link);
  }

  get titleTag() {
    return this.hasLink ? 'a' : 'div';
  }

  @Emit('lazyLoad')
  emitLazyLoad(): void {}

  @Emit('blockShown')
  emitBlockShown(): void {}

  @Emit('headerClicked')
  emitHeaderClicked(): void {}
}






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../../ui-image';
import { UiStatistic } from '../../ui-statistic';
import type { NewsGroupItem, NewsGroupTheme } from '../ui-news-group.contract';

@Component({
  name: 'UiNewsGroupItem',
  components: {
    UiStatistic,
    UiImage,
  },
})
export default class UiNewsGroupItem extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  item: NewsGroupItem;

  @Prop({
    default: 'light',
    type: String,
  })
  theme: NewsGroupTheme;

  @Prop({
    default: false,
    type: Boolean,
  })
  hasBookmarks: boolean;

  get linkClass(): 'primary' | 'secondary' {
    return this.item.isPrimary ? 'primary' : 'secondary';
  }

  get statisticTheme(): 'light' | 'dark' {
    return this.theme === 'dark' ? 'light' : 'dark';
  }

  @Emit('commentClicked')
  emitClickToComment(event: Event): {
    event: Event;
    articleId: number;
  } {
    return { event, articleId: this.item.id };
  }

  @Emit('articleClicked')
  emitClickToArticle(): { articleId: number; place: string } {
    return { articleId: this.item.id, place: this.item.place };
  }

  @Emit('bookmarkClicked')
  emitClickToBookmark(): { articleId: number } {
    return {
      articleId: this.item.id,
    };
  }
}

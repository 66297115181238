





































import { guardEmptyArray } from '@smh/utils/guards';
import { removeHtmlTags } from '@smh/utils/strings';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { UiNewsGroupItem } from './ui-news-group-item';
import type {
  NewsGroupItem,
  NewsGroupEvents,
  NewsGroupTheme,
} from './ui-news-group.contract';

const LARGE_BLOCK_TITLE_LENGTH = 400;

@Component({
  name: 'UiNewsGroup',
  components: { UiNewsGroupItem },
})
export default class UiNewsGroup extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  items!: NewsGroupItem[];

  @Prop({
    default: 'light',
    type: String,
  })
  theme: NewsGroupTheme;

  @Prop({
    default: false,
    type: Boolean,
  })
  hasBookmarks: boolean;

  get mainItem(): NewsGroupItem | undefined {
    return this.items.at(0);
  }

  get tailItems(): NewsGroupItem[] {
    return this.items.slice(1);
  }

  get hasTailItems(): boolean {
    return guardEmptyArray(this.tailItems);
  }

  get isLargeBlock(): boolean {
    return (
      this.tailItems
        .map((item) => item.title.text)
        .map((item) => removeHtmlTags(item))
        .reduce((acc, item) => acc + item.length, 0) > LARGE_BLOCK_TITLE_LENGTH
    );
  }

  @Emit('articleClicked')
  emitClickToArticle(
    event: NewsGroupEvents['onArticleClicked'],
  ): NewsGroupEvents['onArticleClicked'] {
    return event;
  }

  @Emit('commentClicked')
  emitClickToComment(
    event: NewsGroupEvents['onCommentClicked'],
  ): NewsGroupEvents['onCommentClicked'] {
    return event;
  }

  @Emit('bookmarkClicked')
  emitClickToBookmark(
    event: NewsGroupEvents['onBookmarkClicked'],
  ): NewsGroupEvents['onBookmarkClicked'] {
    return event;
  }
}

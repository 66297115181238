type DeviceModification = 'mobile' | 'tablet' | 'laptop' | 'desktop';

type GridModification = 'full' | 'half' | 'oneThird' | 'twoThird';

type GridClass =
  | 'gridFullMobile'
  | 'gridFullTablet'
  | 'gridFullLaptop'
  | 'gridFullDesktop'
  | 'gridHalfMobile'
  | 'gridHalfTablet'
  | 'gridHalfLaptop'
  | 'gridHalfDesktop'
  | 'gridOneThirdMobile'
  | 'gridOneThirdTablet'
  | 'gridOneThirdLaptop'
  | 'gridOneThirdDesktop'
  | 'gridTwoThirdMobile'
  | 'gridTwoThirdTablet'
  | 'gridTwoThirdLaptop'
  | 'gridTwoThirdDesktop';

type ClassConfig = Record<DeviceModification, GridClass>;

export type GridClassConfig = {
  width: Record<GridModification, ClassConfig>;
  height: 'gridMinHeight';
  container: 'gridContainer';
};

export const grid: GridClassConfig = {
  container: 'gridContainer',
  width: {
    full: {
      mobile: 'gridFullMobile',
      tablet: 'gridFullTablet',
      laptop: 'gridFullLaptop',
      desktop: 'gridFullDesktop',
    },
    half: {
      mobile: 'gridHalfMobile',
      tablet: 'gridHalfTablet',
      laptop: 'gridHalfLaptop',
      desktop: 'gridHalfDesktop',
    },
    oneThird: {
      mobile: 'gridOneThirdMobile',
      tablet: 'gridOneThirdTablet',
      laptop: 'gridOneThirdLaptop',
      desktop: 'gridOneThirdDesktop',
    },
    twoThird: {
      mobile: 'gridTwoThirdMobile',
      tablet: 'gridTwoThirdTablet',
      laptop: 'gridTwoThirdLaptop',
      desktop: 'gridTwoThirdDesktop',
    },
  },
  height: 'gridMinHeight',
};





















import { Component, Prop, Vue } from 'vue-property-decorator';

import { grid } from '../../styles/grid/grid';

@Component({
  name: 'UiLayout',
})
export default class UiLayout extends Vue {
  @Prop({
    required: true,
    type: Boolean,
  })
  isRenderLeftCol: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  isRenderRightCol: boolean;

  get classes() {
    return grid;
  }
}
